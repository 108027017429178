import React, { Component } from "react"

import SEO from "../components/seo"
import SiteContext from "../context/SiteContext"
import Layout from "../components/layout"
import Sidebar from "../components/Sidebar"
import SectorSelect from "../components/SectorSelect"
import EmacoApplicationSelect from "../components/EmacoApplicationSelect"
import AttributeSelect from "../components/AttributeSelect"
import ProductList from "../components/ProductList"
import headerArrows from '../images/header-arrows.png'
import lowDustImgEmaco from '../images/low_dust_masteremaco.jpg'
import emacoIntroImage from '../images/intro_image_masteremaco.jpg'

import Airtable from 'airtable'
import { AiOutlineConsoleSql } from "react-icons/ai"

const base = new Airtable({ apiKey: 'patFAJJSH4qK9IYyE.016823232754588f148a3801d5a6a24e0aebd6e28c5c1d01a89710b26dc6f99f' }).base('apptXa6FjiiQLDJAG')

class WelcomeMasterEmaco extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activeStep: 'sector',
            showSectorSelect: false,
            showApplicationSelect: false,
            showAttributeSelect: false,
            showProductPage: false,
            products: [],
            sectors: [],
            applications: [],
            attributes: [],
            filteredAttributes: [],
            selectedSector: '',
            selectedApplication: '',
            selectedAttributes: [],
            availableProducts: [],
            sectorNextEnabled: 'disabled',
            applicationNextEnabled: 'disabled',
            attributeNextEnabled: 'disabled',
            site: 'masteremaco',
            siteTitle: 'Master Builders Solutions Concrete Repairs',
            introContent: 'Selecting the right concrete repair product can be challenging. Using the MasterEmaco product selector tool you can quickly find a solution for your project and estimate the approximate number of bags required. Master Builders Solutions concrete repair line is extensive, providing the highest quality solutions for a wide range of applications for over 100 years.',
            stepOneText: 'Step 1: Choose the project',
            sidebarGroupOneTitle: 'Project',
            sidebarGroupTwoTitle: 'Application',
            sidebarGroupThreeTitle: 'Product Attributes',
            lowDustBoxTitle: 'Looking for low dust technology?',
            lowDustBoxContent: 'Master Builders Solutions is the first to offer vertical and overhead, rapid setting horizontal and self-consolidating full depth concrete repair products formulated with low dust technology. Reducing air dust up to 70-75% while mixing; making jobsites safer for our customers.',
            lowDustBoxLink: 'https://mbcc.sika.com/en-us/products/grouts',
            attributeSelectTitle: 'Step 3: Choose project attributes',
        }
    }

    async componentDidMount() {
        // Get all product data from airtable
        try {
            base('SectorsME').select({ view: 'All Sectors' })
                .eachPage(
                    (records, fetchNextPage) => {

                        const sectors = records.sort(function (a, b) {
                            if (a.fields.Name > b.fields.Name) return 1;
                            if (a.fields.Name < b.fields.Name) return -1;
                            return 0;
                        });
                        this.setState({
                            sectors,
                        });
                        fetchNextPage();
                    }
                );

            base('ApplicationsME').select({ view: 'All Applications' })
                .eachPage(
                    (records, fetchNextPage) => {
                        // gib attributes pls
                        this.setState({
                            applications: records,
                        });
                        fetchNextPage();
                    }
                );

            base('AttributesME').select({ view: 'All Attributes' })
                .eachPage(
                    (records, fetchNextPage) => {
                        const attributes = records.sort(function (a, b) {
                            if (a.fields.Name > b.fields.Name) return 1;
                            if (a.fields.Name < b.fields.Name) return -1;
                            return 0;
                        });
                        this.setState({
                            attributes
                        });
                        fetchNextPage();
                    }
                );

            base('ProductsME').select({ view: 'All Products' })
                .eachPage(
                    (records, fetchNextPage) => {

                        const products = records.sort(function (a, b) {
                            if (a.fields.Name > b.fields.Name) return 1;
                            if (a.fields.Name < b.fields.Name) return -1;
                            return 0;
                        });
                        this.setState({
                            products,
                            showSectorSelect: true
                        });
                        fetchNextPage();
                    }
                );
        } catch (err) {
            console.error(err)
        }

        // Get list of sectors from product data

    }

    addSector = sector => {
        this.setState({
            selectedSector: sector,
            sectorNextEnabled: '',
        })
    }

    addApplication = (application, id) => {
        if (application === 'Epoxy Rebar Primer and Bonding Agent') {
            const epoxyProduct = this.state.products.find(product => {
                return product.fields.Name === 'MasterEmaco P 124'
            })
            this.setState({
                activeStep: 'product',
                showSectorSelect: false,
                showApplicationSelect: false,
                showAttributeSelect: false,
                showProductPage: true,
                availableProducts: [epoxyProduct],
                selectedApplication: '',
                selectedAttributes: [],
            })
        } else if (application === 'Zinc Rebar Primer') {
            const zincProduct = this.state.products.find(product => {
                return product.fields.Name === 'MasterProtect 8100AP'
            })
            this.setState({
                activeStep: 'product',
                showSectorSelect: false,
                showApplicationSelect: false,
                showAttributeSelect: false,
                showProductPage: true,
                availableProducts: [zincProduct],
                selectedApplication: '',
                selectedAttributes: [],
            })
        } else {
            const filteredAttributes = this.state.attributes.filter(attr => {
                if (attr.fields.Applications !== undefined && attr.fields.Applications.includes(id)) {
                    return attr
                }
            })
            this.setState({
                selectedApplication: application,
                applicationNextEnabled: '',
                filteredAttributes,
            })
        }
    }

    isTrue = (arr, arr2) => {
        return arr.every(i => arr2.includes(i));
    }

    addAttributes = attributeObj => {
        const attribute = {
            name: attributeObj.fields.Name,
            id: attributeObj.id
        }

        let newAttributes = []
        if (this.state.selectedAttributes.some(item => item === attribute.name)) {
            newAttributes = this.state.selectedAttributes.filter(function (el) { return el !== attribute.name; });
        } else {
            newAttributes = [...this.state.selectedAttributes, attribute.name]
        }

        this.setState({
            selectedAttributes: newAttributes,
            attributeNextEnabled: '',
        }, () => {
            const availableProducts = []
            if (this.state.selectedAttributes.length) {
                this.state.products.forEach(product => {
                    const productAttrs = product.fields['Lookup for Attributes'] || []
                    // From https://josephkhan.me/check-if-an-array-is-a-subset-of-another-array-in-javascript/
                    const attrsObj = {}
                    productAttrs.forEach((el, index) => {
                        attrsObj[el] = index;
                    });

                    var productHasAttributes = this.state.selectedAttributes.every((el) => {
                        return attrsObj[el] !== undefined; //because 0 is falsy
                    });

                    const attributeLookupField = `Lookup for ${ this.state.selectedApplication } - Attributes`
                    const productAttributes = product.fields[attributeLookupField]

                    if (productAttributes) {
                        const appsObj = {}
                        productAttributes.forEach((el, index) => {
                            appsObj[el] = index;
                        });

                        var productHasApplications = this.state.selectedAttributes.every((el) => {
                            return appsObj[el] !== undefined; //because 0 is falsy
                        });
                    }

                    if (productHasAttributes && productHasApplications && !availableProducts.includes(product)) {
                        availableProducts.push(product)
                    }
                    this.setState({ availableProducts })

                })
            } else {
                this.setState({ availableProducts: [] })
            }
        })
    }

    handleNextClick = step => {
        switch (step) {
            case 'application':
                this.setState({
                    activeStep: 'application',
                    showSectorSelect: false,
                    showApplicationSelect: true,
                    showAttributeSelect: false,
                    showProductPage: false,
                })
                break;
            case 'attribute':
                this.setState({
                    activeStep: 'attribute',
                    showSectorSelect: false,
                    showApplicationSelect: false,
                    showAttributeSelect: true,
                    showProductPage: false,
                })
                break;
            case 'product':
                this.setState({
                    activeStep: 'product',
                    showSectorSelect: false,
                    showApplicationSelect: false,
                    showAttributeSelect: false,
                    showProductPage: true,
                })
                break;
            default:
                break;
        }
    }

    handleBackClick = step => {
        switch (step) {
            case 'sector':
                this.setState({
                    activeStep: 'sector',
                    showSectorSelect: true,
                    showApplicationSelect: false,
                    showAttributeSelect: false,
                    selectedApplication: '',
                    selectedAttributes: [],
                    availableProducts: [],
                })
                break;
            case 'application':
                this.setState({
                    activeStep: 'application',
                    showSectorSelect: false,
                    showApplicationSelect: true,
                    showAttributeSelect: false,
                    showProductPage: false,
                    selectedAttributes: [],
                    availableProducts: [],
                })
                break;
            case 'attribute':
                this.setState({
                    activeStep: 'attribute',
                    showSectorSelect: false,
                    showApplicationSelect: false,
                    showAttributeSelect: true,
                    showProductPage: false,
                })
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <SiteContext.Consumer>
                {siteContext => (
                    <div className="site-masteremaco">
                        <Layout>
                            <SEO title="MasterEmaco – Concrete Repairs Selector Tool" description="A digital service that helps estimate and choose the correct concrete repair product on construction projects." />
                            <div className="wrapper wrapper--tool">
                                <Sidebar
                                    selectedSector={this.state.selectedSector}
                                    selectedApplication={this.state.selectedApplication}
                                    selectedAttributes={this.state.selectedAttributes}
                                    availableProducts={this.state.availableProducts} 
                                    activeStep={this.state.activeStep}
                                    site={this.state.site}
                                    sidebarGroupOneTitle={this.state.sidebarGroupOneTitle}
                                    sidebarGroupTwoTitle={this.state.sidebarGroupTwoTitle}
                                    sidebarGroupThreeTitle={this.state.sidebarGroupThreeTitle}
                                />
                                <div className="content-wrapper">
                                    {this.state.showSectorSelect
                                        ? <SectorSelect
                                            sectors={this.state.sectors}
                                            addSector={this.addSector}
                                            selectedSector={this.state.selectedSector}
                                            handleNextClick={this.handleNextClick}
                                            handleBackClick={this.handleBackClick}
                                            sectorNextEnabled={this.state.sectorNextEnabled} 
                                            availableProducts={this.state.availableProducts}
                                            products={this.state.products}
                                            site={this.state.site}
                                            siteTitle={this.state.siteTitle}
                                            introContent={this.state.introContent}
                                            introImage={emacoIntroImage}
                                            stepOneText={this.state.stepOneText}
                                            lowDustBoxTitle={this.state.lowDustBoxTitle}
                                            lowDustBoxContent={this.state.lowDustBoxContent}
                                            lowDustBoxImage={lowDustImgEmaco}
                                            lowDustBoxLink={this.state.lowDustBoxLink}
                                            next="application"
                                        />
                                        : null}

                                    {this.state.showApplicationSelect
                                        ? <EmacoApplicationSelect
                                            applications={this.state.applications}
                                            addApplication={this.addApplication}
                                            selectedSector={this.state.selectedSector}
                                            selectedApplication={this.state.selectedApplication}
                                            handleNextClick={this.handleNextClick}
                                            handleBackClick={this.handleBackClick}
                                            applicationNextEnabled={this.state.applicationNextEnabled} 
                                            availableProducts={this.state.availableProducts}
                                            products={this.state.products}
                                            next="attribute"
                                            previous="sector"
                                        />
                                        : null}

                                    {this.state.showAttributeSelect
                                        ? <AttributeSelect
                                            attributes={this.state.filteredAttributes}
                                            addAttributes={this.addAttributes}
                                            selectedSector={this.state.selectedSector}
                                            selectedApplication={this.state.selectedApplication}
                                            selectedAttributes={this.state.selectedAttributes}
                                            handleNextClick={this.handleNextClick}
                                            handleBackClick={this.handleBackClick}
                                            attributeNextEnabled={this.state.attributeNextEnabled} 
                                            availableProducts={this.state.availableProducts}
                                            site={this.state.site}
                                            products={this.state.products}
                                            attributeSelectTitle={this.state.attributeSelectTitle}
                                            next="product"
                                            previous="application"
                                        />
                                        : null}

                                    {this.state.showProductPage
                                        ? <ProductList
                                            availableProducts={this.state.availableProducts}
                                            handleNextClick={this.handleNextClick}
                                            handleBackClick={this.handleBackClick} 
                                            selectedSector={this.state.selectedSector}
                                            selectedApplication={this.state.selectedApplication}
                                            selectedAttributes={this.state.selectedAttributes}
                                            products={this.state.products}
                                            site={this.state.site}
                                            previous="attribute"
                                        />
                                        : null}

                                </div>
                            </div>
                        </Layout>
                    </div>
                )}
            </SiteContext.Consumer>
        )
    }
}

export default WelcomeMasterEmaco